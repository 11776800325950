import { Button, notification } from 'antd';
import axios from 'axios';
import { OnBoardingContext } from '.';
import { useContext } from 'react';

export const logoutAllDevice = () => {
  const { userData, setOpenMultiLoginAlert } = useContext(OnBoardingContext);
  axios({
    method: 'POST',
    headers: {
      Authorization: `Token ${userData?.message?.token}`,
    },
    url: `${process.env.NEXT_PUBLIC_BASE_URL}user-logout-all-devices/`,
  })
    .then((res) => {
      setOpenMultiLoginAlert(false);
      notification.success({ message: 'Logged out of all devices successfully' });
    })
    .catch((e) => {
      notification.error({ message: 'Failed to log out' });
    });
};

export const MultipleLoginContent = () => {
  const { userData } = useContext(OnBoardingContext);

  return (
    <>
      {userData?.message?.body?.length !== 0 &&
        userData?.message?.body?.map((each) => (
          <div className="d-flex">
            <div className="font-size-large gray-da-text">
              <i className="fas fa-desktop"></i>
            </div>
            <div className="d-flex flex-column p-3 px-4">
              <div className="font-weight-bold">
                {each?.platform} -{each?.browser_name}
              </div>
              <div className="gray-da-text">{each?.ip_address}</div>
            </div>
          </div>
        ))}
      <div className="text-center mt-3">
        <Button className="btn-secondary border-danger" onClick={logoutAllDevice}>
          Logout of all devices
        </Button>
      </div>
    </>
  );
};
