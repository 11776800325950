import GoogleLogin from 'react-google-login';
import { useContext, useEffect, useState } from 'react';
import { OnBoardingContext } from '.';
import { Button, Input, Select, Switch, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { requestSignupOtp } from '@/actions/auth';
import useWidth from '@/helpers/widthUtil';
import { CountryCodeSelector } from '@/helpers/CountryCodeSelector';
import countries, { country_codes } from '@/helpers/countries';
import { blockInvalidChar } from '@/helpers/formDataHelper';
import { InfoCircleFilled } from '@ant-design/icons';
import CustomCheckBox from '@/components/CustomCheckBox';
import Link from 'next/link';

const SignupForm = () => {
  const [password2, setPassword2] = useState('');

  //checking if referer code is there or not
  const [hasreferer, setHasReferer] = useState(false);
  const dispatch = useDispatch();
  const { isMobile } = useWidth();
  const { Option } = Select;

  const {
    userDataRegister,
    auth: { user, address },
  } = useSelector((state: any) => ({
    fromLogoutPage: state.auth.fromLogOut,
    userData: state.auth.creds,
    userDataRegister: state.auth.register,
    auth: state.auth,
  }));

  const {
    googleResponse,
    errors,
    setErrors,
    handleFilter,
    setIsNewUserNepali,
    setExpandOtpForm,
    phoneValidation,
    handleSelectFilter,
    handleLogin,
    CustomGoogleButton,
  } = useContext(OnBoardingContext);

  useEffect(() => {
    return () => setPassword2('');
  }, []);

  const submitSignupForm = (event) => {
    event.preventDefault();
    if (user?.phone_number?.length < 10) {
      setErrors({
        ...errors,
        phone_number: 'Phone number must be at least 10 digits',
      });
    }
    if (user?.password?.length <= 8) {
      setErrors({
        ...errors,
        password: 'Password must have at least 8 characters',
      });
    }
    if (!user?.country_code) {
      setErrors({
        ...errors,
        password: 'Please select country code',
      });
    }
    if (
      user?.phone_number?.length >= 10 &&
      user?.password?.length >= 8 &&
      user?.password === password2
    ) {
      if (user?.country_code === '+977') {
        setIsNewUserNepali(true);
        dispatch(requestSignupOtp({ phone_number: user?.phone_number }));
      } else {
        setExpandOtpForm(true);
        phoneValidation(event);
      }
    }
    // dispatch(userRegister(user));
  };

  return (
    <>
      <form onSubmit={submitSignupForm}>
        <div className="row ">
          <div className="col-lg-6">
            <div className="form-group">
              <label>First Name</label>
              <Input
                name="first_name"
                type="text"
                className="border-14"
                onChange={handleFilter}
                placeholder="First Name"
                value={user?.first_name || ''}
                size="large"
                required
              />
            </div>
            {userDataRegister?.error?.first_name && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.first_name}
              </span>
            )}
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Last Name</label>
              <Input
                name="last_name"
                type="text"
                className="border-14"
                onChange={handleFilter}
                placeholder="Last Name"
                value={user?.last_name || ''}
                size="large"
                required
              />
            </div>
            {userDataRegister?.error?.last_name && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.last_name}
              </span>
            )}
          </div>
        </div>
        <div className="form-group">
          <label>Email</label>
          <Input
            name="email"
            type="email"
            className="border-14"
            onChange={handleFilter}
            placeholder="Email"
            value={user?.email || ''}
            size="large"
            required
          />
        </div>
        {userDataRegister?.error?.email && (
          <span className="validation mb-2">
            <i className="fas fa-exclamation-triangle"></i>{' '}
            {userDataRegister?.error?.email}
          </span>
        )}
        {errors?.email && (
          <span className="validation">
            <i className="fas fa-exclamation-triangle"></i> {errors?.email}
          </span>
        )}
        <div className="row mt-1">
          <div className={`${isMobile ? 'col-4' : 'col-md-4 col-lg-4 col-sm-4'}`}>
            <div className="form-group country-code-selector">
              <label className="text-left ml-1">Code</label>
              <CountryCodeSelector
                onChange={(val) => handleSelectFilter('country_code', val)}
                defaultValue={
                  country_codes?.find(
                    (each) => each?.code == address?.data?.countryCode
                  )?.mobileCode || '+977'
                }
              />
            </div>

            {errors?.country_code && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {errors?.country_code}
              </span>
            )}
          </div>
          <div
            className={`${isMobile ? 'col-8' : 'col-md-8 col-lg-8 col-sm-8 pl-0'}`}
          >
            <div className="form-group">
              <label className="text-left ml-1">Phone</label>

              <Input
                id="phone_input"
                name="phone_number"
                type="number"
                onChange={handleFilter}
                placeholder="Phone Number"
                value={user?.phone_number || ''}
                size="large"
                required
                onKeyDown={blockInvalidChar}
                className="border-14"
              />
            </div>
            {userDataRegister?.error?.phone_number && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.phone_number}
              </span>
            )}
            {errors?.phone_number && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {errors?.phone_number}
              </span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Country</label>
              <Select
                showSearch
                style={{ width: '100%' }}
                value={user?.country || ''}
                // className="border-14"
                placeholder="Select Country"
                optionFilterProp="children"
                onChange={(val) => handleSelectFilter('country', val)}
              >
                {Object.entries(countries).map((each, idx) => (
                  <Option key={idx} value={each[1]}>
                    {each[1]}
                  </Option>
                ))}
              </Select>
            </div>
            {userDataRegister?.error?.country && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.country}
              </span>
            )}
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>City</label>
              <Input
                name="city"
                type="text"
                className="border-14"
                onChange={handleFilter}
                placeholder="City"
                value={user?.city || ''}
                size="large"
                required
              />
            </div>
            {userDataRegister?.error?.city && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.city}
              </span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label>Create Password</label>
              <Input.Password
                name="password"
                onChange={handleFilter}
                value={user?.password || ''}
                size="large"
                className="border-14"
              />
            </div>
            {userDataRegister?.error?.password && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.password}
              </span>
            )}
            {errors?.password && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i> {errors?.password}
              </span>
            )}
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Confirm Password</label>
              <Input.Password
                name="confirm_password"
                type="password"
                onChange={(e) => setPassword2(e.target.value)}
                value={password2 || ''}
                size="large"
                className="border-14"
              />
            </div>
            {user?.password && password2 && user?.password !== password2 ? (
              <span className="validation">
                <i className="fas fa-exclamation-triangle"></i> Passwords Do not
                Match
              </span>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="mb-3">
          <div className="d-flex gap-3 align-items-center">
            <p className="m-0 font-weight-bold">Have a Referral Code?</p>
            <Switch
              defaultChecked
              style={{ backgroundColor: '#1c599f' }}
              checked={hasreferer}
              onChange={(val) => setHasReferer(val)}
            />
          </div>
          {hasreferer && (
            <div className="row mt-2">
              <div className="col-lg-12">
                <div>
                  <div className="d-flex gap-1 align-items-center">
                    <label>Referer Code</label>
                    <Tooltip
                      placement="top"
                      title={'Please input username of users as referer code.'}
                    >
                      <InfoCircleFilled
                        rev={''}
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: '#f17425',
                          marginBottom: '8px',
                        }}
                      />
                    </Tooltip>
                  </div>

                  <Input
                    name="referer"
                    type="text"
                    onChange={handleFilter}
                    value={user?.referer || ''}
                    size="large"
                    className="border-14"
                    required
                  />
                </div>
                {errors?.referer && (
                  <span className="validation mb-2">
                    <i className="fas fa-exclamation-triangle"></i> {errors?.referer}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between">
          <label
            className="d-flex justify-content-start"
            htmlFor="termsAndCondition"
          >
            <CustomCheckBox>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="termsAndCondition"
                required
              />
            </CustomCheckBox>
            <span className="mt-auto">
              I Agree to{' '}
              <Link href="/terms-conditions">
                <a target="_blank" className="mt-auto text-secondary">
                  <u> Terms and Conditions.</u>
                </a>
              </Link>
            </span>
          </label>
        </div>
        <Button
          htmlType="submit"
          size="large"
          className="btn-danger text-white border-danger btn-block mt-2 mb-2 border-14"
        >
          Join Now
        </Button>
        <p className="account mb-3 text-center">
          Already have an account?{' '}
          <span onClick={() => handleLogin('login')} className="text-secondary">
            <u>Login</u>
          </span>
        </p>
      </form>

      <div className="row mt-2 mb-2">
        <span className="border-option col-lg-4 d-none d-lg-block ml-4 my-auto"></span>
        <p className="option col-lg-2 my-auto mx-auto text-center"> OR </p>
        <span className="border-option d-none d-lg-block col-lg-4 my-auto"></span>
      </div>
      <GoogleLogin
        clientId={process?.env?.NEXT_PUBLIC_GOOGLE_APP_ID}
        onFailure={(err) => console.log('err', err)}
        render={(renderProps) => (
          <CustomGoogleButton onClick={renderProps.onClick} />
        )}
        onSuccess={googleResponse}
        className="btn btn-block font-weight-normal"
        cookiePolicy={'single_host_origin'}
      />
    </>
  );
};

export default SignupForm;
